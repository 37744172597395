<template>
  <ImageWrapper
    :image="image"
    :config="imageConfig"
    :classes="{
      image: imageClassList,
      container: containerClassList,
      placeholder: imageClassList,
    }"
  >
    <template #default="{ classList }">
      <ImagePlaceholder
        :class="classList ?? ''"
        :icon="placeholder?.icon ?? 'kh:gallery-image'"
        :classes="placeholder?.classes"
      />
    </template>
  </ImageWrapper>
</template>

<script setup lang="ts">
interface Props {
  imageFormat: GalleryImageFormat
  imageConfig: ImageConfig
  image: ImageObject
  placeholder?: ImagePlaceholder
  classes?: {
    container?: string
  }
}

const props = defineProps<Props>()

defineOptions({
  name: 'GalleryImage',
})

const { containerClassList, imageClassList } = useGalleryClasses(
  props.imageFormat
)
</script>
