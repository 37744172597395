<template>
  <div>
    <button
      class="button button-secondary h-[42px] min-w-[44px] space-x-2 pl-1 pr-1 data-[icon]:!h-10 data-[icon]:!w-10 data-[prefix]:!pl-2.5 data-[suffix]:!pr-2.5 md:min-w-[100px] md:pl-4 md:pr-4 [&>svg]:h-6 [&>svg]:w-6"
      :class="isMovieOnWatchList ? 'border-primary' : 'border-current'"
      @click="handle"
    >
      <Icon
        v-if="isMovieOnWatchList"
        name="kh:bookmark-solid"
        :class="isMovieOnWatchList ? 'text-primary' : 'text-current'"
      />

      <Icon
        v-else
        name="kh:bookmark-outline"
        :class="isMovieOnWatchList ? 'text-primary' : 'text-current'"
      />
      <span class="hidden text-nowrap break-keep md:block" v-text="text" />
    </button>

    <AuthModal
      v-if="isAuthModalVisible"
      :type="USER_AUTH_STATE.LOGIN"
      @close="isAuthModalVisible = false"
    />
  </div>
</template>

<script setup lang="ts">
import type { Movie } from '#gql/default'
import { USER_AUTH_STATE } from '@account/constants/user'

interface Props {
  movie: Movie
}

const props = defineProps<Props>()

const { t } = useI18n()

const { isLoggedIn } = useUserAuth()
const { add: addMessage } = useMessage()
const { add, remove, fetch, movies } = useWatchList()

const isAuthModalVisible = ref(false)
const shouldAddAfterLogin = ref(false)

const isMovieOnWatchList = computed(() => {
  return movies.value?.some((movie: Movie) => movie.id === props.movie.id)
})

const text = computed(() => {
  return isMovieOnWatchList.value ? t('movieAdded') : t('addMovie')
})

async function handle() {
  if (isLoggedIn.value && isMovieOnWatchList.value) {
    try {
      await remove({ movieId: props.movie.id })
    } catch (error) {
      addMessage({
        title: t('errorRemovingMovie'),
        message: t('tryAgain'),
        type: MESSAGE_TYPE.TOAST,
        severity: MESSAGE_SEVERITY.ERROR,
      })
    }
  } else if (isLoggedIn.value && !isMovieOnWatchList.value) {
    try {
      await add({ movieId: props.movie.id })
      shouldAddAfterLogin.value = false
    } catch (error) {
      addMessage({
        title: t('errorAddingMovie'),
        message: t('tryAgain'),
        type: MESSAGE_TYPE.TOAST,
        severity: MESSAGE_SEVERITY.ERROR,
      })
    }
  } else {
    shouldAddAfterLogin.value = true
    isAuthModalVisible.value = true
  }
}

// Adds the movie to the watch list after the user logs in if they clicked the button before
watch(isLoggedIn, async () => {
  if (shouldAddAfterLogin.value) {
    shouldAddAfterLogin.value = false
    await handle()
  }
})

if (isLoggedIn.value) {
  try {
    await fetch()
  } catch (error) {
    addMessage({
      title: t('errorFetchingWatchlist'),
      message: t('tryAgain'),
      type: MESSAGE_TYPE.TOAST,
      severity: MESSAGE_SEVERITY.ERROR,
    })
  }
}
defineOptions({
  name: 'WatchListButton',
})
</script>

<i18n>
de:
  addMovie: "Film merken"
  movieAdded: "Film gemerkt"
  errorAddingMovie: "Fehler beim Hinzufügen des Films"
  errorRemovingMovie: "Fehler beim Entfernen des Films"
  errorFetchingWatchlist: "Fehler beim Laden der Merkliste"
  tryAgain: "Bitte erneut versucht"
es:
  addMovie: "Añadir película"
  movieAdded: "Película añadida"
  errorAddingMovie: "Error al añadir la película"
  errorRemovingMovie: "Error al eliminar la película"
  errorFetchingWatchlist: "Error al cargar la lista de deseos"
  tryAgain: "Por favor, inténtalo de nuevo"
</i18n>
