<template>
  <ul class="space-y-4 leading-7">
    <li v-if="movie.directors.length">
      <strong class="block" v-text="t('label.director')" />
      <span
        v-text="joinArrayOfObjectsByAttribute(movie.directors, 'name', 3)"
      />
    </li>
    <li v-if="movie.productionCountries.length">
      <strong class="block" v-text="t('label.productionCountry')" />
      <span
        v-text="
          joinArrayOfObjectsByAttribute(movie.productionCountries, 'name', 1)
        "
      />
    </li>
    <li v-if="movie.productionYear">
      <strong class="block" v-text="t('label.productionYear')" />
      <span v-text="movie.productionYear" />
    </li>

    <li v-if="!hideFacts && movie.duration">
      <strong class="block" v-text="t('label.duration')" />
      <span v-text="t('duration', { duration: movie.duration })" />
    </li>

    <li v-if="!hideFacts && movie.genres.length">
      <strong class="block" v-text="t('label.genre')" />
      <template v-if="!disableLinks">
        <NuxtLink
          v-for="{ urlSlug, name, id } in movie.genres"
          :key="`${urlSlug}-${id}`"
          :to="
            localeRoute({
              name: ROUTE_NAMES.GENRE_PAGE,
              params: {
                genreSlug: urlSlug,
              },
            })
          "
          class="inline-flex text-base font-light after:mr-2 after:inline-flex after:content-[','] last-of-type:after:content-[''] hover:underline"
        >
          {{ name }}
        </NuxtLink>
      </template>
      <span
        v-else
        v-text="joinArrayOfObjectsByAttribute(movie.genres, 'name', 3)"
      />
    </li>

    <li v-if="movie.actors.length && showCast">
      <strong class="block" v-text="t('label.cast')" />
      <PersonList
        :persons="movie.actors"
        :limit="5"
        :hide-link="!!disableLinks"
      />
    </li>

    <li v-if="!hideFacts && movie.contentRating?.contentRatingSystem">
      <strong class="block" v-text="t('label.contentRating')" />
      <NuxtLink v-if="!disableLinks" to="/jugendschutz" class="hover:underline">
        <span v-html="formatContentRating(movie.contentRating)" />
      </NuxtLink>
      <div v-else v-html="formatContentRating(movie.contentRating)" />
    </li>

    <li v-if="movie.jugendFilmJury?.jfjAgeFrom">
      <div class="flex flex-row space-x-1">
        <strong class="block" v-text="t('label.ageRecommendation')" />
        <button
          class="inline fill-current align-middle"
          :title="t('btn.jfjInfo')"
          @click="isJugendFilmJuryModalOpen = true"
        >
          <Icon name="kh:information-outline" class="size-5" />
        </button>
      </div>

      <span
        v-text="
          t('ageRecommendation', { ageFrom: movie.jugendFilmJury.jfjAgeFrom })
        "
      />

      <MovieJfjModal
        v-if="isJugendFilmJuryModalOpen"
        @close="isJugendFilmJuryModalOpen = false"
      />
    </li>

    <li
      v-if="
        movie.rating?.name &&
        movie.rating?.rating &&
        movie.rating?.maximalRating
      "
    >
      <strong class="block" v-text="movie.rating.name" />
      <span
        v-text="
          t('voting', {
            rating: movie.rating.rating,
            maximalRating: movie.rating.maximalRating,
            voteCount: movie.rating.voteCount,
          })
        "
      />
    </li>
  </ul>
</template>

<script setup lang="ts">
import type { Movie } from '#gql/default'

interface Props {
  movie: Movie
  showCast?: boolean
  disableLinks?: boolean
  hideFacts?: boolean
}

defineProps<Props>()

const { t } = useI18n()
const isJugendFilmJuryModalOpen = ref(false)

defineOptions({
  name: 'MovieShortInfo',
})
</script>

<i18n>
de:
  label:
    productionCountry: "Produktionsländer"
    productionYear: "Produktionsjahr"
    director: "Regie"
    genre: "Genre"
    duration: "Dauer"
    contentRating: "Altersfreigabe"
    cast: "Cast"
    ageRecommendation: "Empfehlung der Jugendfilmjury"
  btn:
    jfjInfo: "Mehr Informationen zur Jugend Film Jury"
  duration: "{duration} Minuten"
  voting: "{rating}/{maximalRating} ({voteCount}) Stimmen"
  ageRecommendation: "{ageFrom} - 99"
es:
  label:
    productionCountry: "País de producción"
    productionYear: "Año de producción"
    director: "Director"
    genre: "Género"
    duration: "Duración"
    contentRating: "Clasificación por edades"
    cast: "Reparto"
    ageRecommendation: "Recomendación de la Jugend Film Jury"
  btn:
    jfjInfo: "Más información sobre la Jugend Film Jury"
  duration: "{duration} minutos"
  voting: "{rating}/{maximalRating} ({voteCount}) votos"
  ageRecommendation: "{ageFrom} - 99"
</i18n>
