<template>
  <div
    class="w-full items-center space-y-3 text-center"
    @touchstart.passive="handleTouchStart"
  >
    <div v-if="isToday(date)" data-image v-text="t('today')" />
    <i18n-d
      v-else
      data-image
      tag="div"
      :value="new Date(date)"
      :format="{
        weekday: 'short',
        day: '2-digit',
        month: '2-digit',
      }"
    />

    <div class="space-y-2">
      <ShowLink
        v-for="show in filteredShows"
        :key="show.id"
        :booking-modal
        :show
        :link-params
        class="from-neutral to-neutral-dark-1 hover:bg-neutral-dark-1 dark:hover:bg-neutral-light-1 hover:from-neutral-light-1 hover:to-neutral flex flex-col justify-center rounded-lg bg-gradient-to-b from-[44px] to-[44px] transition-colors"
      />

      <Button
        v-if="!showAll && shows.length > 2"
        :title="t('btn.showMore')"
        :theme="BUTTON_THEME.TERTIARY"
        :size="BUTTON_SIZE.SM"
        icon="kh:chevron-down"
        :text="t('btn.more')"
        @click="showAll = true"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { LinkParam, Show } from '#gql/default'

interface Props {
  shows: Show[]
  date: string
  bookingModal?: boolean
  linkParams?: LinkParam[]
}

const props = defineProps<Props>()
const emit = defineEmits(['touchstart'])

const { t } = useI18n()

const showAll = ref(props.shows.length <= 1)
const filteredShows = computed(() =>
  showAll.value ? props.shows : props.shows.slice(0, 2)
)

function handleTouchStart() {
  emit('touchstart')
}

function isToday(date: string) {
  return new Date(date).toDateString() === new Date().toDateString()
}

defineOptions({
  name: 'MoviePlaytimesDayList',
})
</script>

<i18n>
de:
  today: "Heute"
  btn:
    showMore: "Mehr anzeigen"
    more: "mehr"
es:
  today: "Hoy"
  btn:
    showMore: "Mostrar más"
    more: "más"
</i18n>
